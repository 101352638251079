<template>
  <div class="service">
    <div class="service_img">
      <van-image width="50%" height="50%" :src="require('@/assets/imgs/kefu.jpg')" />
      <p style="font-size:2em;margin-top:1em;">如有问题，请添加客服微信进行查询</p>
      <p style="font-size:2em;">或拨打客服电话：19827699063</p>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" @click="click">首页</van-tabbar-item>
      <van-tabbar-item icon="service-o">客服</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { getQueryVariable } from "@/util/utils.js"
export default {
  data () {
    return {
      active: 1
    }
  },
  methods: {
    click () {
      this.$router.push({
        path: '/index',
        query: {
          supplierId: getQueryVariable("supplierId"),
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.service {
  height: 100%;
  width: 100%;

  .service_img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 50%;
  }
}
</style>
